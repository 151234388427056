/* eslint-disable */
import { userAuth } from "@/state/user";
import { TaxiType } from "../constants/common";

export default {
  data() {
    return {
      userStore: null,
    };
  },
  created() {
    this.userStore = userAuth();
  },
  methods: {
    $formatPhone(inputNumber) {
      if (inputNumber) {
        let numberString = inputNumber.toString();

        // Check if the formattedNumber starts with '84'
        if (numberString.startsWith("84")) {
          // Replace '84' with '0'
          numberString = "0" + numberString.slice(2);
        }

        return numberString;
      }
      return "";
    },

    $formatDate(inputDate) {
      if (inputDate) {
        const date = new Date(inputDate);
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based, so we add 1
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
      } else {
        return "";
      }
    },
    $formatDateTime(inputDate) {
      if (inputDate) {
        const date = new Date(inputDate);
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based, so we add 1
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");
        const seconds = date.getSeconds().toString().padStart(2, "0");

        return ` ${hours}:${minutes}:${seconds} ${day}/${month}/${year}`;
      } else {
        return "";
      }
    },
    $formatDateTime2(inputDate) {
      if (inputDate) {
        const date = new Date(inputDate);
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based, so we add 1
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");

        return ` ${day}/${month}/${year} ${hours}:${minutes}`;
      } else {
        return "";
      }
    },

    $checkDeliveryStatus(input) {
      if (input === "DELIVERED") {
        return '<span class="badge bg-success" > Đã giao hàng</span>';
      } else if (input === "WAITING") {
        return '<span class="badge bg-info ">Đang chờ</span>';
      }
      if (input === "CANCEL") {
        return '<span class="badge bg-warning">Đã hủy</span> ';
      }
      if (input === "REJECT") {
        return '<span class="badge bg-danger">Đã từ chối</span> ';
      }
      if (input === "DELIVERING") {
        return '<span class="badge bg-dark" ">Đang chuyển phát</span> ';
      }

      return input;
    },

    $checkUserKYC(inputKYCdata) {
      if (inputKYCdata === 0) {
        return '<i class=" ri-error-warning-line text-danger d-inline-block" style="transform: translateY(3px);"></i> Chưa xác thực';
      } else if (inputKYCdata === 1) {
        return '<i class="ri-time-line text-warning d-inline-block" style="transform: translateY(3px);"></i> Chờ xác thực';
      } else if (inputKYCdata === 2) {
        return '<i class="ri-checkbox-circle-fill text-success d-inline-block" style="transform: translateY(3px);"></i> Đã xác thực';
      } else if (inputKYCdata === 3) {
        return '<i class="ri-close-circle-line text-dark d-inline-block" style="transform: translateY(3px);"></i> Đã từ chối';
      } else {
        // Handle other cases or return a default value if necessary
        return "";
      }
    },
    $checkUserState(inputStateData, inputKYCData) {
      if (inputKYCData == 0) {
        return '<i class="ri-checkbox-blank-circle-fill text-danger d-inline-block" style="transform: translateY(2px);"></i> Không hoạt động';
      } else {
        if (inputStateData === "active" || inputStateData === 1) {
          return '<i class="ri-checkbox-blank-circle-fill text-info d-inline-block" style="transform: translateY(2px);"></i> Đang hoạt động';
        } else if (inputStateData === "inactive" || inputStateData === 0) {
          return '<i class="ri-checkbox-blank-circle-fill text-danger d-inline-block" style="transform: translateY(2px);"></i> Không hoạt động';
        } else {
          // Handle other cases or return a default value if necessary
          return "";
        }
      }
    },
    $userBANDuration(param) {
      if (param === "ONE_DAY") {
        return "1 ngày";
      } else if (param === "ONE_WEEK") {
        return "1 tuần";
      } else if (param === "FOREVER") {
        return "Vĩnh viễn";
      } else {
        // Handle other cases or return a default value if necessary
        return param;
      }
    },
    $checkCarKYC(inputKYCdata) {
      if (inputKYCdata === 1) {
        return '<i class="ri-checkbox-circle-fill text-success d-inline-block" style="transform: translateY(3px);"></i> Đã xác thực';
      } else if (inputKYCdata === 2) {
        return '<i class="ri-close-circle-line text-dark d-inline-block" style="transform: translateY(3px);"></i> Đã từ chối';
      } else {
        // Handle other cases or return a default value if necessary
        return '<i class="ri-time-line text-warning d-inline-block" style="transform: translateY(3px);"></i> Chờ xác thực';
      }
    },
    $checkCarModel(input) {
      if (input === TaxiType.CAR4SEATS) {
        return "Xe taxi 4 chỗ";
      } else if (input === TaxiType.CAR7SEATS) {
        return "Xe taxi 7 chỗ";
      } else if (input === TaxiType.MOTOBIKE) {
        return "Xe máy";
      } else if (input === TaxiType.CARLUXURY) {
        return "Taxi VIP";
      } else {
        // Handle other cases or return a default value if necessary
        return "-";
      }
    },
    $checkFindxuTransactionStatus(input, deleted, hold) {
      if (hold) {
        return '<i class="ri-close-circle-line text-warning d-inline-block" style="transform: translateY(3px);"></i> Đã từ chối Không hoàn xu';
      } else if (deleted && hold == false) {
        return '<i class="ri-close-circle-line text-dark d-inline-block" style="transform: translateY(3px);"></i> Đã từ chối Hoàn xu';
      } else if (deleted && hold == undefined) {
        return '<i class="ri-close-circle-line text-dark d-inline-block" style="transform: translateY(3px);"></i> Đã từ chối';
      } else if (input === 0 || input === false) {
        return '<i class="ri-time-line text-warning d-inline-block" style="transform: translateY(3px);"></i> Chờ xác thực';
      } else if (input === 1 || input === true) {
        return '<i class="ri-checkbox-circle-fill text-success d-inline-block" style="transform: translateY(3px);"></i> Đã xác thực';
      } else {
        // Handle other cases or return a default value if necessary
        return "";
      }
    },
    $checkFindxuTransactionType(input) {
      if (input === "transfer") {
        return '<i class="ri-time-line d-inline-block" style="transform: translateY(3px); color: blue;"></i> Chuyển khoản';
      } else if (input === "recharge") {
        return '<i class="ri-time-line d-inline-block" style="transform: translateY(3px); color: green;"></i> Nạp xu';
      } else if (input === "withdraw") {
        return '<i class="ri-time-line d-inline-block" style="transform: translateY(3px); color: red;"></i> Rút xu';
      } else if (input === "reward") {
        return '<i class="ri-time-line d-inline-block" style="transform: translateY(3px); color: orange;"></i> Thưởng';
      } else if (input === "used") {
        return '<i class "ri-time-line d-inline-block" style="transform: translateY(3px); color: purple;"></i> Sử dụng';
      } else if (input === "system") {
        return '<i class="ri-time-line d-inline-block" style="transform: translateY(3px); color: black;"></i> Hệ thống';
      } else if (input === "system_register") {
        return '<i class="ri-time-line d-inline-block" style="transform: translateY(3px); color: brown;"></i> Hệ thống đăng ký';
      } else {
        // Handle other cases or return a default value if necessary
        return '<i class="ri-time-line d-inline-block" style="transform: translateY(3px); color: gray;"></i> Khác';
      }
    },
    $findxuWithdrawExchange(input) {
      // Multiply the adjusted input by 2000
      const withdrawalAmount = 0.8 * input * 2000;
      // Return the calculated value
      // Format the withdrawalAmount to two decimal places
      const formattedAmount = withdrawalAmount.toLocaleString("vi-VN");

      // Return the formatted value
      return formattedAmount;
    },
    $numberFormatter(inputText) {
      // First, convert the input text to a number.
      const numberValue = parseFloat(inputText);

      // Check if the conversion was successful and the input is a valid number.
      if (!isNaN(numberValue)) {
        // Now, format the number with commas for thousands separation.
        const formattedNumber = numberValue.toLocaleString("vi-VN");
        return formattedNumber;
      } else {
        // Handle the case where the input text is not a valid number.
        return "Invalid input, please enter a numeric value.";
      }
    },
    $postStatus(input) {
      if (input == "published") {
        return "Đã xuất bản";
      } else if (input == "draft") {
        return "Lưu nháp";
      }
    },

    $userHaveRight(targetKey, inputType) {
      // return true
      // console.log(this.userStore.isAdmin);
      if (this.userStore.isAdmin == true) {
        return true;
      } else {
        const userPermissions = this.userStore?.userRoleGroupData?.permissions;
        // console.log(userPermissions);
        if (userPermissions) {
          if (userPermissions && targetKey in userPermissions) {
            return true;
          }

          if (userPermissions[targetKey]) {
            return true;
          } else {
            for (let key in userPermissions) {
              if (userPermissions[key][targetKey]) {
                const _permis = userPermissions[key][targetKey][inputType];
                if (_permis == 1) {
                  return true;
                }
              }
            }
          }
          // return true;
          return false;
        } else {
          // return true;
          return false;
        }
      }
    },

    $checkAdsStatus(input) {
      if (input == 1) {
        return '<span class="badge bg-success" >Đã duyệt</span>';
      } else if (input == 4) {
        return '<span class="badge bg-danger" > Đã từ chối</span>';
      } else if (input == 2) {
        return '<span class="badge bg-dark" > Đã hoàn thành</span>';
      } else if (input == 3) {
        return '<span class="badge bg-warning" > Chờ duyệt</span>';
      } else {
        // Handle other cases or return a default value if necessary
        return "";
      }
    },

    $checkAdsActive(input) {
      if (input == 1) {
        return '<i class="ri-checkbox-circle-line text-success d-inline-block" style="transform: translateY(3px);"></i> Đang hoạt động';
      } else if (input == 0) {
        return '<i class="ri-close-circle-line text-danger d-inline-block" style="transform: translateY(3px);"></i> Đã ẩn';
      } else {
        // Handle other cases or return a default value if necessary
        return "";
      }
    },

    $priceFomatter(num) {
      return Math.abs(num) >= 1000000
        ? (Math.abs(num) / 1000000).toFixed(1) + " triệu"
        : this.$numberFormatter(Math.abs(num));
    },

    $kFormatter(num) {
      return Math.abs(num) > 999
        ? (Math.abs(num) / 1000).toFixed(1) + "k"
        : Math.abs(num);
    },

    $truncateString(str, maxLength = 30) {
      if (str.length > maxLength) {
        return str.slice(0, maxLength) + "...";
      }
      return str;
    },

    $clampText(text, maxLines) {
      const lines = text.split("\n");
      if (lines.length > maxLines) {
        return lines.slice(0, maxLines).join("\n") + "\n...";
      }
      return text;
    },
    $formatCurrency(amount) {
      const formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND",
      });
      const formattedAmount = formatter.format(amount || 0);
      return formattedAmount;
    },
    $formatTypeBusiness(type) {
      return type === +0 ? 'Cá nhân' : 'Công ty'
    },
    $formatInsurancePackageTime(time) {
      switch (time) {
        case 1:
          return '1 năm'

        case 3:
          return '3 năm'

        case 5:
          return '5 năm'

        default:
          return '-'
      }
    }
  },
};
