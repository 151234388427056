export const API_DOMAIN = process.env.VUE_APP_API_DOMAIN_1;
export const API_DOMAIN_UPLOAD = process.env.VUE_APP_API_DOMAIN_UPLOAD;
export const NODE_ENV = process.env.VUE_APP_ENV;
export const API_GOONG = 'https://rsapi.goong.io';
export const API_GOONG_KEY = 'oB1jq1dNAdPXp39pji3NWAen8Vin0gwQsc0O5SIz';
// REFUND STATUS
export const BUYER_REQUEST = 0; //  đang chờ phê duyệt
export const ADMIN_ACCEPT_BUYER_REQUEST = 1; // đã được phê duyệt
export const ADMIN_REJECT_BUYER_REQUEST = 2; // đã từ chối
export const SELLER_REQUEST_DELIVERY = 3; //  người bán yêu cầu người mua gửi trả hàng
export const BUYER_CONFIRM_DELIVERY = 4; // người mua xác nhận đã chuyển hàng
export const SELLER_CONFIRM_RECEIVED = 5; // người bán xác nhận đã nhận hàng
export const SELLER_ACCEPT_REFUND = 6; // đồng ý hoàn tiền
export const SELLER_REQUEST_COMPLAIN = 7; // người bán yêu cầu tranh chấp
export const ADMIN_CONFIRM_BUYER = 8; // admin từ chối khiếu nại
export const ADMIN_CONFIRM_SELLER = 9; // admin chấp nhận khiếu nại

export const refundStatus = new Map([
  [BUYER_REQUEST, "Đang chờ phê duyệt"],
  [ADMIN_REJECT_BUYER_REQUEST, "Đã từ chối"],
  [ADMIN_ACCEPT_BUYER_REQUEST, "Đã được phê duyệt"],
]);

export const complaintStatus = new Map([
  [SELLER_REQUEST_COMPLAIN, "Đang chờ phê duyệt"],
  [ADMIN_CONFIRM_BUYER, "Đã từ chối"],
  [ADMIN_CONFIRM_SELLER, "Đã được phê duyệt"],
]);

export const orderStatus = {
  'WAITING': 'WAITING',
  'REJECT': 'REJECT',
  'ACCEPT': 'ACCEPT',
  'INPROGRESS': 'INPROGRESS',
  'CONTACT_SHIPPING': 'CONTACT_SHIPPING',
  'DELIVERING': 'DELIVERING',
  'DELIVERED': 'DELIVERED',
  'FAILED': 'FAILED',
  'CANCEL': 'CANCEL',
  'REFUND': 'REFUND',
  'COMPLETE': 'COMPLETE',
}

export const TaxiType = {
  CARLUXURY: 'CARLUXURY',
  CAR4SEATS: 'CAR4SEATS',
  CAR7SEATS: 'CAR7SEATS',
  MOTOBIKE: 'MOTOBIKE',
};

export const InsuranceDateQuery = {
  'ONE_YEAR': 'ONE_YEAR',
  'THREE_YEAR': 'THREE_YEAR',
  'FIVE_YEAR': 'FIVE_YEAR',
  'OTHER': 'OTHER',
}

export const VehicleTypeQuery = {
  PERSONAL: 'PERSONAL',
  CORPORATE: 'CORPORATE',
}

export const InsurancePackageTypeQuery = {
  MOTOBIKE: 'MOTOBIKE',
  CAR: 'CAR',
}